import { registerLocaleData } from '@angular/common';
import { APP_INITIALIZER, inject, Injectable, LOCALE_ID } from '@angular/core';
import { loadTranslations } from '@angular/localize';
import { Store } from '@ngxs/store';
import { AppState, AppStateSetLanguage } from './app.state';
import { LANGUAGE_KEY } from './app.consts';
import { LanguageEnum } from '@loreal-doctors-video-platform/data';

@Injectable({
  providedIn: 'root',
})
class I18n {
  private readonly _store = inject(Store);
  private readonly _navigatorLanguage = navigator.language;

  public locale =
    localStorage.getItem(LANGUAGE_KEY)?.replace(/"/g, '') ||
    (this._navigatorLanguage === 'pl-PL' ? LanguageEnum.PL : LanguageEnum.EN);

  async setLocale() {
    const userLocale = this._store.selectSnapshot(AppState.language);

    // If the user has a preferred language stored in localStorage, use it.
    if (userLocale) {
      this.locale = userLocale;
      if (this.locale !== LanguageEnum.PL && this.locale !== LanguageEnum.EN) {
        this.locale = LanguageEnum.PL;
        this._store.dispatch(new AppStateSetLanguage({ language: LanguageEnum.PL }));
      }
    }

    // Use web pack magic string to only include required locale data
    const localeModule = await import(
      /* webpackInclude: /(pl|en)\.mjs$/ */
      `../../../../node_modules/@angular/common/locales/${this.locale}.mjs`
    );

    // Set locale for built in pipes, etc.
    registerLocaleData(localeModule.default);

    // Load translation file
    const localeTranslationsModule = await import(`../assets/i18n/${this.locale}.json`);

    // Load translations for the current locale at run-time
    loadTranslations(localeTranslationsModule.default.translations);
  }
}

// Load locale data at app start-up
function setLocale() {
  return {
    provide: APP_INITIALIZER,
    useFactory: (i18n: I18n) => () => i18n.setLocale(),
    deps: [I18n],
    multi: true,
  };
}

// Set the runtime locale for the app
function setLocaleId() {
  return {
    provide: LOCALE_ID,
    useFactory: (i18n: I18n) => i18n.locale,
    deps: [I18n],
  };
}

export const I18nModule = {
  setLocale: setLocale,
  setLocaleId: setLocaleId,
};
