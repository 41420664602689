import {
  AfterViewInit,
  DestroyRef,
  Directive,
  DoCheck,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
  selector: '[appInputValidator]',
  standalone: true,
})
export class InputValidatorDirective implements OnInit, AfterViewInit, DoCheck {
  @Input('appInputValidator')
  public control: AbstractControl | null = null;
  private _valCssClassSubj = new Subject<string>();
  private _floatLabelSubj = new Subject<string>();
  private _touched = false;

  constructor(
    private readonly _destroyRef: DestroyRef,
    private readonly _renderer2: Renderer2,
    private readonly _elementRef: ElementRef,
  ) {}

  public ngAfterViewInit(): void {
    this._floatLabelSubj.next(this.control?.value);
  }

  public ngOnInit(): void {
    this._floatLabelSubj.next(this.control?.value);
    this._initSubscriptions();
  }

  public ngDoCheck(): void {
    if (this._touched !== this.control?.touched) {
      this._touched = this.control?.touched || false;
      this._valCssClassSubj.next(this.control?.value);
    }
  }

  @HostListener('focusout', ['$event.target'])
  public onFocusout() {
    this._floatLabelSubj.next(this.control?.value);
    this._valCssClassSubj.next(this.control?.value);
  }

  private _initSubscriptions(): void {
    const _handleErrorClass = (control: AbstractControl) => {
      if (control.valid) {
        this._renderer2.removeClass(this._elementRef.nativeElement, 'status-danger');
      } else {
        this._renderer2.addClass(this._elementRef.nativeElement, 'status-danger');
      }
    };

    this._valCssClassSubj
      .pipe(takeUntilDestroyed(this._destroyRef), debounceTime(100), distinctUntilChanged())
      .subscribe(() => {
        if (!this.control?.touched && !this.control?.dirty) {
          return;
        }
        _handleErrorClass(this.control);
      });

    this._floatLabelSubj
      .pipe(takeUntilDestroyed(this._destroyRef), debounceTime(25), distinctUntilChanged())
      .subscribe((value) => {
        this._handleValueChange(value);
      });

    this.control?.valueChanges
      .pipe(takeUntilDestroyed(this._destroyRef), distinctUntilChanged(), debounceTime(100))
      .subscribe((value) => {
        this._handleValueChange(value);
      });
  }

  private _handleValueChange(value: string): void {
    if (value !== null && value !== undefined && value !== '' && value?.length !== 0) {
      this._renderer2.addClass(this._elementRef.nativeElement, 'used');
    } else {
      this._renderer2.removeClass(this._elementRef.nativeElement, 'used');
    }
  }
}
