import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../environments/environment';

export function isJwtTokenExpired(token: string): boolean {
  if (!token) {
    return true;
  }
  const jwtHelperService: JwtHelperService = new JwtHelperService();
  let isTokenExpired: boolean;
  try {
    const decodedToken = jwtHelperService.decodeToken(token);
    if (!environment.production) {
      console.warn('decodedToken', decodedToken);
    }
    isTokenExpired = jwtHelperService.isTokenExpired(token) as boolean;
  } catch (e) {
    console.error('isTokenExpired error', e);
    isTokenExpired = true;
  }
  if (!environment.production) {
    console.warn('isTokenExpired', isTokenExpired);
  }
  return isTokenExpired;
}
