import { isPlatformServer } from '@angular/common';
import { AfterViewInit, Component, DestroyRef, inject, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ToastrService } from 'ngx-toastr';
import { skip } from 'rxjs/operators';
import { NetworkService } from './_services/network.service';
import { OneTrustService } from './_services/one-trust.service';
import { GoogleTagManagerService } from './_services/google-tag-manager.service';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { Store } from '@ngxs/store';
import { AppState } from './app.state';

@Component({
  selector: 'dvp-root',
  template: ` <router-outlet /> `,
})
export class AppComponent implements OnInit, AfterViewInit {
  private readonly _toastrService: ToastrService = inject(ToastrService);

  constructor(
    private readonly _ngbModal: NgbModal,
    private readonly _router: Router,
    @Inject(PLATFORM_ID)
    private readonly _platformId: string,
    private readonly _networkService: NetworkService,
    private readonly _oneTrustService: OneTrustService,
    private readonly _googleTagManagerService: GoogleTagManagerService,
    private readonly _angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private readonly _destroyRef: DestroyRef,
    private readonly _store: Store,
  ) {}

  public ngOnInit(): void {
    this._setRouterSubscription();
    this._setOnlineOfflineChangesSubscription();
  }

  public ngAfterViewInit(): void {
    this._addSpyScripts();
  }

  private _addSpyScripts(): void {
    this._oneTrustService
      .addScriptToDom()
      .then(() => {
        this._googleTagManagerService
          .addScriptToDom()
          .then(() => {
            this._angulartics2GoogleTagManager.startTracking();
            const user = this._store.selectSnapshot(AppState.user);
            const language = this._store.selectSnapshot(AppState.language);
            this._angulartics2GoogleTagManager.pushLayer({
              event: 'pageDataReady',
              brand: 'LDB',
              language: language,
              country: user ? user.country : 'unknown',
              geoIPLocation: 'unknown',
              siteTypeLevel: 'main',
              pageCategory: 'homepage',
              uiLoggedStatus: user ? 'regular logged' : 'not loggged',
            });
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  private _setOnlineOfflineChangesSubscription(): void {
    if (isPlatformServer(this._platformId)) {
      return;
    }
    this._networkService.onlineChanges$.pipe(takeUntilDestroyed(this._destroyRef), skip(1)).subscribe((online) => {
      if (online) {
        this._toastrService.info($localize`:@@app.internet-connection-restored:Internet connection restored.`);
      } else {
        this._toastrService.warning($localize`:@@app.internet-connection-lost:Internet connection lost.`);
      }
    });
  }

  private _setRouterSubscription(): void {
    if (isPlatformServer(this._platformId)) {
      return;
    }
    this._router.events.subscribe((routeEvent) => {
      if (routeEvent instanceof NavigationEnd) {
        this._ngbModal.dismissAll();
      }
    });
  }
}
