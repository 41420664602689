@if (eventName && startDate() && endDate() && startTime() && endTime()) {
  <div class="acd-wr-add-to-cal-btn">
    <add-to-calendar-button
      #addToCalendarButton
      [attr.endDate]="endDate()"
      [attr.endTime]="endTime()"
      [attr.label]="label"
      [attr.language]="language"
      [attr.name]="eventName"
      [attr.startDate]="startDate()"
      [attr.startTime]="startTime()"
      listStyle="dropdown"
      forceOverlay="false"
      hideBackground="true"
      hideCheckmark="true"
      hideIconButton="true"
      options="'Apple','Google','iCal','Outlook.com','Microsoft 365','Microsoft Teams','Yahoo'"
      timeZone="Europe/Warsaw"
    ></add-to-calendar-button>
  </div>
}
<!--[attr.customCss]="cssFileUrl"-->
<!--buttonStyle="custom"-->
