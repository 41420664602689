export const environment = {
  production: true,
  development: false,
  test: false,
  url: 'https://dermproacademy.lorealdermatologicalbeauty.pl',
  ws: 'https://dermproacademy.lorealdermatologicalbeauty.pl/api/test-chat-connection',
  api: 'https://dermproacademy.lorealdermatologicalbeauty.pl/api',
  chat: 'https://dermproacademy.lorealdermatologicalbeauty.pl/chat',
  upload: 'https://dermproacademy.lorealdermatologicalbeauty.pl/api/uploads',
  gtm: 'GTM-WZT996F9',
  recaptchaV3SiteKey: '6LfMMUglAAAAAB7fcQPJ3zaoYiUDr5yX_FwV4FQw',
  flowPlayerPlayerID: 'b661fd46-eeac-4f1d-9a2d-0b1a6bfac2fd',
  flowPlayerToken:
    'eyJraWQiOiJxV3FyRWFmQXV1UjUiLCJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJjIjoie1wiYWNsXCI6NixcImlkXCI6XCJxV3FyRWFmQXV1UjVcIn0iLCJpc3MiOiJGbG93cGxheWVyIn0.Aup4eNhtBC-Ffn5EvFzoBdxFTCx14odRjkEtcss3ldCpo2JMbeD5Sv4P-IuMXRbmiA2a_TbJcZCqTZJKR4TPTQ',
  oneScriptDataDomainKey: '41facafe-0c8f-4ee4-8f3b-243ca3d0003d',
  chatKey:
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJtZXJjdXJlIjp7InB1Ymxpc2giOltdLCJzdWJzY3JpYmUiOlsia3Vycy10cmljaG9za29waWkiXX19.TIDVp6ZvakFJUl56M0nhi9wnNtOvePvzLtpxcm6uV0E',
};
