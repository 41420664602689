import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AppState } from '../app.state';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  constructor(private readonly _store: Store) {}

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const language = this._store.selectSnapshot(AppState.language);
    if (!language) {
      throw new Error('Language not set');
    }
    if (req.url.includes('hd.hipokrates.org')) {
      return next.handle(req);
    }
    req = req.clone({
      setHeaders: {
        'Selected-Language': language,
      },
    });
    return next.handle(req);
  }
}
