import { format } from 'date-fns';
import { convertWarsawDateStringToCurrentTimezoneDate } from './convert-warsaw-date-string-to-current-timezone-date';
import { enUS, pl } from 'date-fns/locale';
import { LanguageEnum } from '../../data';

export function extractDatetimeToDateAndTime(
  datetime: string | null,
  currentLanguage: LanguageEnum | null,
): { date: string | null; time: string | null } {
  if (!datetime || !currentLanguage) {
    return { date: null, time: null };
  }

  const date = convertWarsawDateStringToCurrentTimezoneDate(datetime);
  let formattedDate = format(date, 'MMMM dd, yyyy', {
    locale: currentLanguage === LanguageEnum.PL ? pl : enUS,
  });
  if (currentLanguage === LanguageEnum.PL) {
    formattedDate = format(date, 'dd MMMM yyyy', {
      locale: currentLanguage === LanguageEnum.PL ? pl : enUS,
    });
  }
  return {
    date: formattedDate,
    time: format(date, 'HH:mm', {
      locale: currentLanguage === LanguageEnum.PL ? pl : enUS,
    }),
  };
}
