import { DestroyRef, Injectable } from '@angular/core';
import { OnRegisterLoggedClientToWebinar } from '../_tokens/register-logged-client-to-webinar.token';
import { Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { AppState, AppStateRegisterLoggedClientToWebinar } from '../app.state';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { throwError } from 'rxjs';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmAddingEventToCalendarModalComponent } from '../pages/core/components/confirm-adding-event-to-calendar-modal/confirm-adding-event-to-calendar-modal.component';
import { WebinarType } from '@loreal-doctors-video-platform/data';

@Injectable({ providedIn: 'root' })
export class RegisterLoggedClientToWebinarService implements OnRegisterLoggedClientToWebinar {
  constructor(
    private readonly _store: Store,
    private readonly _toastrService: ToastrService,
    private readonly _destroyRef: DestroyRef,
    private readonly _ngbModal: NgbModal,
  ) {}

  public onRegisterLoggedClientToWebinar({
    slug,
    eventTitle,
    startDateTime,
    endDateTime,
    type,
  }: {
    slug: string;
    eventTitle: string;
    startDateTime: string;
    endDateTime: string;
    type: WebinarType;
  }): void {
    this._store
      .dispatch(new AppStateRegisterLoggedClientToWebinar({ slug }))
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe({
        next: () => {
          if (type !== WebinarType.EVENT) {
            this._toastrService.success(
              $localize`:@@app.successfully-signed-to-event:You have successfully signed up for the event.`,
              'Success',
            );
          }
          if (type !== WebinarType.EVENT) {
            this._openConfirmAddingEventToCalendar({ eventName: eventTitle, startDateTime, endDateTime });
          }
        },
        error: (error) => {
          this._toastrService.error(
            $localize`:@@app.something-went-wrong:Something went wrong.`,
            $localize`:@@app.error:Error`,
          );
          console.error(error);
          if (error.status === 400 && error.error?.message) {
            this._toastrService.error(error.error.message, $localize`:@@app.error:Error`);
          }
          return throwError(() => error);
        },
      });
  }

  private _openConfirmAddingEventToCalendar({
    eventName,
    startDateTime,
    endDateTime,
  }: {
    eventName: string;
    startDateTime: string;
    endDateTime: string;
  }): void {
    const modalWindowOptions: NgbModalOptions = {
      backdrop: true,
      keyboard: true,
      centered: true,
      windowClass: 'acd-add-event-to-calendar-modal-window',
      modalDialogClass: 'acd-add-event-to-calendar-modal-dialog',
    };
    const modalRef = this._ngbModal.open(ConfirmAddingEventToCalendarModalComponent, modalWindowOptions);
    modalRef.componentInstance.eventName = eventName;
    modalRef.componentInstance.startDateTime = startDateTime;
    modalRef.componentInstance.endDateTime = endDateTime;
    console.warn('modalRef.componentInstance.startDate', modalRef.componentInstance);
    modalRef.componentInstance.language = this._store.selectSnapshot(AppState.language);
    modalRef.result.catch(() => {});
  }
}
