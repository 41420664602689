import { getSafeValue } from './safe-value';

declare const json2html: any;

export function jsonToHtml(val: string): string {
  if (!val) {
    throw 'Json value must be defined!';
  }
  const json = JSON.parse(val);
  const html = json2html(json);
  return getSafeValue(html);
}
