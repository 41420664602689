import { Routes } from '@angular/router';
import { RouteSlug } from './_models/route-slug.enum';
import { SsrGuard } from './_guards/ssr.guard';
import { AuthGuard } from './_guards/auth.guard';
import { AppSettings } from './app.settings';

export const APP_ROUTES: Routes = [
  {
    path: RouteSlug.SSR_REDIRECT,
    loadComponent: () =>
      import('./ssr-redirect/ssr-redirect.component').then(({ SsrRedirectComponent }) => SsrRedirectComponent),
  },
  {
    path: `${RouteSlug.LIVE_CHAT}/:slug`,
    canActivate: [SsrGuard, AuthGuard],
    loadComponent: () => import('./pages/chat/chat.component').then(({ ChatComponent }) => ChatComponent),
    title: `${AppSettings.TITLE_PREFIX} - Chat`,
  },
  {
    path: `${RouteSlug.SURVEY}/:slug`,
    canActivate: [SsrGuard],
    loadComponent: () => import('./pages/survey/survey.component').then(({ SurveyComponent }) => SurveyComponent),
    title: `${AppSettings.TITLE_PREFIX} - Survey`,
  },
  {
    path: '',
    loadChildren: () => import('./pages/core/core.routing').then(({ CORE_ROUTES }) => CORE_ROUTES),
  },
];
