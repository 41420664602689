import { inject, InjectionToken, PLATFORM_ID } from '@angular/core';
import { getWindow } from 'ssr-window';

export const WINDOW = new InjectionToken<Window>('global Window object', {
  providedIn: 'root',
  factory: () => {
    const platform = inject(PLATFORM_ID);
    return platform === 'browser' ? window : getWindow();
  },
});
