import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, DestroyRef, DoCheck, Inject, Input, Optional } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, skipWhile } from 'rxjs/operators';
import { validatorErrorMessage } from '../../utils';
import { OnTranslateValidationMessages, TRANSLATE_VALIDATION_MESSAGES } from '../../tokens';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-validate-message',
  template: `
    <ng-container *ngIf="control?.touched">
      <p *ngIf="errorMessage$ | async as errorMessage" [@enterAnimation] class="vmc-error">
        {{ errorMessage }}
      </p>
    </ng-container>
  `,
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)', opacity: 0 }),
        animate('150ms', style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('150ms', style({ transform: 'translateX(100%)', opacity: 0 })),
      ]),
    ]),
  ],
  standalone: true,
  imports: [CommonModule],
})
export class ValidateMessageComponent implements DoCheck {
  private _errorMsg = new BehaviorSubject<string>('');
  public errorMessage$ = this._errorMsg.asObservable();
  private _touched = false;

  constructor(
    @Inject(TRANSLATE_VALIDATION_MESSAGES)
    @Optional()
    private readonly _onTranslateValidationMessages: OnTranslateValidationMessages,
    private readonly _destroyRef: DestroyRef,
  ) {}

  private _control: AbstractControl | null = null;

  get control(): AbstractControl | null {
    return this._control;
  }

  @Input()
  set control(control: AbstractControl | null) {
    this._control = control;
    this._setValueChangeSubscription();
    this._setStatusChangeSubscription();
  }

  public ngDoCheck(): void {
    if (this._touched !== this.control?.touched) {
      this._touched = this.control?.touched || false;
      this._setErrorMessage();
    } else if (this.control?.errors && this.control?.errors['minlength']) {
      this._setErrorMessage();
    }
  }

  private _setValueChangeSubscription(): void {
    this.control?.valueChanges
      .pipe(takeUntilDestroyed(this._destroyRef), debounceTime(25), distinctUntilChanged())
      .subscribe(() => {
        this._setErrorMessage();
      });
  }

  private _setStatusChangeSubscription(): void {
    this.control?.statusChanges
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        debounceTime(25),
        distinctUntilChanged(),
        skipWhile((status: string) => status !== 'INVALID'),
      )
      .subscribe(() => {
        this._setErrorMessage();
      });
  }

  private _setErrorMessage(): void {
    let exists = false;
    for (const propertyName in this.control?.errors) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.control?.errors.hasOwnProperty(propertyName)) {
        exists = true;

        const errorMessage = this._onTranslateValidationMessages
          ? this._onTranslateValidationMessages?.onTranslateValidationMessages(
              propertyName,
              this.control?.errors[propertyName],
            )
          : validatorErrorMessage(propertyName, this.control.errors[propertyName]);
        this._errorMsg.next(errorMessage);
      }
    }
    if (!exists) {
      this._errorMsg.next('');
    }
  }
}
