import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { WINDOW } from '@loreal-doctors-video-platform/data';
import { environment } from '../../environments/environment';
import { Store } from '@ngxs/store';
import { AppState } from '../app.state';

@Injectable({
  providedIn: 'root',
})
export class OneTrustService {
  private readonly _renderer2: Renderer2;

  constructor(
    @Inject(WINDOW)
    public readonly _window: Window,
    @Inject(DOCUMENT)
    private readonly _document: Document,
    private readonly _rendererFactory: RendererFactory2,
    private readonly _store: Store,
  ) {
    this._renderer2 = this._rendererFactory.createRenderer(null, null);
  }

  public addScriptToDom(): Promise<unknown> {
    return new Promise((resolve, reject) => {
      const secondScript = this._renderer2.createElement('script');
      secondScript.id = 'acv-one-trust-first-script';
      secondScript.charset = 'UTF-8';
      secondScript.type = 'text/javascript';
      secondScript.innerHTML = `
        function OptanonWrapper() { }
      `;
      this._document.head.insertBefore(secondScript, this._document.head.firstChild);

      const firstScript = this._renderer2.createElement('script');
      firstScript.id = 'one-trust-second-script';
      firstScript.defer = true;
      firstScript.async = true;
      firstScript.onload = resolve;
      firstScript.onerror = reject;
      firstScript.charset = 'UTF-8';
      firstScript.type = 'text/javascript';
      const language = this._store.selectSnapshot(AppState.language);
      this._renderer2.setAttribute(firstScript, 'data-language', language);
      this._renderer2.setAttribute(firstScript, 'data-document-language', 'true');
      this._renderer2.setAttribute(firstScript, 'data-domain-script', environment.oneScriptDataDomainKey);
      firstScript.src = `https://cdn.cookielaw.org/scripttemplates/otSDKStub.js`;
      this._document.head.insertBefore(firstScript, this._document.head.firstChild);
      return null;
    });
  }
}
