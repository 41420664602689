import * as XSS from 'xss';

export function getSafeValue(value: string): string {
  const whiteList: any = XSS.getDefaultWhiteList();
  for (const key in whiteList) {
    whiteList[key].push('class');
  }
  whiteList['marquee'] = ['direction'];
  whiteList.span.push('style');
  whiteList.video.push('muted');
  whiteList.img.push('data-youtube');
  whiteList.div.push('style', 'id');
  whiteList.i = ['class'];
  whiteList.a.push('data-friser', 'data-hairstore');
  const filterXSS = new XSS.FilterXSS({
    whiteList,
    css: {
      whiteList: {
        color: true,
        'background-color': true,
        'font-size': true,
        'font-family': true,
        'line-height': true,
        display: true,
      },
    },
  });
  return filterXSS.process(value);
}
