import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'polishPlural',
  standalone: true,
})
export class PolishPluralPipe implements PipeTransform {
  public transform(
    value: number | null,
    singularNominativ = 'wynik',
    pluralNominativ = 'wyniki',
    pluralGenitive = 'wyników',
  ): string {
    if (value === null) {
      return '';
    }
    if (value === 1) {
      return `${value} ${singularNominativ}`;
    } else if (value % 10 >= 2 && value % 10 <= 4 && (value % 100 < 10 || value % 100 >= 20)) {
      return `${value} ${pluralNominativ}`;
    } else {
      return `${value} ${pluralGenitive}`;
    }
  }
}
