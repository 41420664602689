import { Inject, Injectable } from '@angular/core';

import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { mergeMap, Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { RouteSlug } from '../_models/route-slug.enum';
import { SERVICES_CUSTOMER_TOKEN } from '@loreal-doctors-video-platform/data';
import {
  AppState,
  AppStateClearUserData,
  AppStateGetUserData,
  AppStateLogout,
  AppStateSetJwtToken,
} from '../app.state';
import { isJwtTokenExpired } from '../_helpers/is-jwt-token-expired';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private readonly _store: Store,
    @Inject(SERVICES_CUSTOMER_TOKEN)
    private readonly _servicesCustomerToken: () => string,
  ) {}

  public canActivate(): Observable<boolean> {
    const token = this._servicesCustomerToken();

    if (!token || isJwtTokenExpired(token)) {
      this._store.dispatch(new AppStateClearUserData());
      return this._goToLoginPage();
    }

    const user = this._store.selectSnapshot(AppState.user);
    if (!user) {
      return this._store.dispatch(new AppStateGetUserData()).pipe(
        catchError((err) => {
          if (err?.status === 403) {
            this._store
              .dispatch(new AppStateLogout())
              .pipe(mergeMap(() => this._store.dispatch(new AppStateSetJwtToken({ token: null }))));
          }
          this._goToLoginPage();
          return throwError(() => err);
        }),
      );
    }
    return of(true);
  }

  private _goToLoginPage(): Observable<boolean> {
    return of(false).pipe(
      tap(() => {
        this._store.dispatch(
          new Navigate([`/${RouteSlug.AUTH}`, RouteSlug.LOGIN], { returnUrl: window.location.pathname }),
        );
      }),
    );
  }
}
