import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  inject,
  Input,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import 'add-to-calendar-button';
import { LanguageEnum } from '@loreal-doctors-video-platform/data';
import { WrapAddToCalendarButtonComponent } from '../wrap-add-to-calendar-button/wrap-add-to-calendar-button.component';
import { GtmClickDirective } from '../../../../_directives/gtm-click.directive';
import { Title } from '@angular/platform-browser';

@Component({
  templateUrl: './confirm-adding-event-to-calendar-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, WrapAddToCalendarButtonComponent, GtmClickDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmAddingEventToCalendarModalComponent implements AfterViewInit {
  public readonly title = inject(Title);

  @ViewChild('addToCalendarButton', { static: false, read: ElementRef })
  public addToCalendarButton!: ElementRef;
  @Input()
  public eventName: string | null = null;
  @Input()
  public language!: LanguageEnum;
  public readonly label = $localize`:@@confirm-adding-event-to-calendar-modal.yes:Yes`;
  @Input({ required: true })
  public startDateTime: string | null = null;
  @Input({ required: true })
  public endDateTime: string | null = null;
  private readonly _ngbActiveModal: NgbActiveModal = inject(NgbActiveModal);
  private readonly _renderer2: Renderer2 = inject(Renderer2);

  public closePopup(): void {
    this._ngbActiveModal.close(false);
  }

  public ngAfterViewInit(): void {
    const nativeElement: HTMLElement = this.addToCalendarButton.nativeElement;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const addCalendarBtn: any = nativeElement.querySelector('add-to-calendar-button');
    const shadowRoot: ShadowRoot | null | undefined = addCalendarBtn?.shadowRoot;
    const wrapper = shadowRoot?.querySelector('.atcb-button-wrapper');
    this._renderer2.setStyle(wrapper, 'padding', '0');
    const button = shadowRoot?.querySelector('.atcb-button');
    this._renderer2.setStyle(button, 'font-size', '13px');
    this._renderer2.setStyle(button, 'max-width', '100%');
    this._renderer2.setStyle(button, 'min-width', '184px');
    this._renderer2.setStyle(button, 'height', '48px');
    this._renderer2.setStyle(button, 'line-height', '48px');
    this._renderer2.setStyle(button, 'padding', '0');
    this._renderer2.setStyle(button, 'margin', '0');
    this._renderer2.setStyle(button, 'border-radius', '9px');
  }
}
