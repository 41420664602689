import { OnTranslateValidationMessages } from '@loreal-doctors-video-platform/data';

export class TranslateValidationMessages implements OnTranslateValidationMessages {
  public onTranslateValidationMessages(validatorName: string, validatorValue?: any): string {
    const passwordsMessage = $localize`:@@validation-message.invalid-password:The password should contain at least 8 characters, including a lowercase and uppercase letter, a special character and a number.`;
    const config: {
      [key: string]: string;
    } = {
      validatePhoneNumber: $localize`:@@validation-message.phone-number:This value is invalid. Please enter a phone number without area code and without spaces.`,
      cardNumber: $localize`:@@validation-message.card-number:Please enter a valid card number.`,
      mask: $localize`:@@validation-message.mask:Please enter the value in the correct format.`,
      email: $localize`:@@validation-message.email:Please enter a valid email address.`,
      required: $localize`:@@validation.message.required:This field is required.`,
      maxlength: $localize`:@@validation-message.max-length:Maximum field length is ${validatorValue['requiredLength']}.`,
      minlength: $localize`:@@validation-message.min-length:Minimum field length is ${validatorValue['requiredLength']}.`,
      matchOther: $localize`:@@validation-message.match-other:The passwords must be the same.`,
      hasUpper: passwordsMessage,
      hasSpecial: passwordsMessage,
      hasNumber: passwordsMessage,
      hasLower: passwordsMessage,
      ngbDate: $localize`:@@validation-message.date:Please enter a valid date.`,
      // Podany numer PWZ nie należy do lekarza.
      isNotDoctor: $localize`:@@validation-message.is-not-doctor:This PWZ number does not belong to a doctor.`,
      // Podany numer PWZ jest nieprawidłowy.
      pzwNumberIsNotValid: $localize`:@@validation-message.pzw-number-is-not-valid:This PWZ number is not valid.`,
      // Podana data urodzenia jest nieprawidłowa.
      birthDateIsNotValid: $localize`:@@validation-message.birth-date-is-not-valid:This birth date is not valid.`,
      // Podany numer PWZ i data urodzenia są nieprawidłowe.
      pzwNumberAndBirthDateAreNotValid: $localize`:@@validation-message.pzw-number-and-birth-date-are-not-valid:This PWZ number and birth date are not valid.`,
      // Adres IP jest nieprawidłowy.
      ipAddressIsNotValid: $localize`:@@validation-message.ip-address-is-not-valid:This IP address is not valid.`,
      // Błąd połączenia z bazą danych.
      databaseConnectionError: $localize`:@@validation-message.database-connection-error:Database connection error.`,
      // Aktualizacja danych.
      dataUpdate: $localize`:@@validation-message.data-update:Data update.`,
      // Nie rozpoznano odpowiedzi z PZW.
      notRecognizedPzwResponse: $localize`:@@validation-message.not-recognized-pzw-response:Not recognized PZW response.`,
    };
    return config[validatorName];
  }
}
