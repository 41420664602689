export function validatorErrorMessage(validatorName: string, validatorValue?: any): string {
  const passwordsMessage =
    'Hasło powinno zawierać co najmniej 8 znaków, w tym małą i dużą literę, znak specjalny i cyfrę.';
  const config: {
    [key: string]: string;
  } = {
    validatePhoneNumber: 'Ta wartość jest niepoprawna. Wpisz numer telefonu bez numeru kierunkowego i bez spacji.',
    cardNumber: 'Podaj poprawny numer karty.',
    mask: 'Podaj wartość w poprawnym formacie.',
    nip: 'Podaj poprawny numer NIP.',
    pesel: 'Podaj poprawny numer pesel.',
    email: 'Niepoprawny format adresu e-mail.',
    required: 'To pole jest obowiązkowe.',
    maxlength: `Maksymalna długość pola to ${validatorValue['requiredLength']}.`,
    minlength: `Minimalna długość pola to ${validatorValue['requiredLength']}.`,
    matchOther: `Podane hasła muszą być identyczne.`,
    hasUpper: passwordsMessage,
    hasSpecial: passwordsMessage,
    hasNumber: passwordsMessage,
    hasLower: passwordsMessage,
    ngbDate: 'Podaj poprawną datę.',
  };
  return config[validatorName];
}
