import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  HostListener,
  inject,
  Input,
  Renderer2,
  signal,
  ViewChild,
  ViewEncapsulation,
  WritableSignal,
} from '@angular/core';
import 'add-to-calendar-button';
import { LanguageEnum } from '@loreal-doctors-video-platform/data';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'dvp-wrap-add-to-calendar-button',
  templateUrl: './wrap-add-to-calendar-button.component.html',
  styleUrls: ['./wrap-add-to-calendar-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  encapsulation: ViewEncapsulation.None,
})
export class WrapAddToCalendarButtonComponent implements AfterViewInit {
  @ViewChild('addToCalendarButton', { static: false, read: ElementRef })
  public addToCalendarButton!: ElementRef;
  public readonly startDate: WritableSignal<null | string> = signal(null);
  public readonly endDate: WritableSignal<null | string> = signal(null);
  public readonly startTime: WritableSignal<null | string> = signal(null);
  public readonly endTime: WritableSignal<null | string> = signal(null);
  public readonly innerWidth: WritableSignal<number> = signal(window.innerWidth);
  @Input({ required: true })
  public eventName: string | null = null;
  @Input({ required: true })
  public language!: LanguageEnum;
  @Input()
  public label = $localize`:@@wrap-add-to-calendar-button.add-event-to-calendar:Add event to calendar`;
  private readonly _renderer2: Renderer2 = inject(Renderer2);

  public readonly cssFileUrl = `${environment.url}/assets/css/add-to-calendar-button.css`;

  private _startDateTime: string | null = null;

  get startDateTime(): string | null {
    return this._startDateTime;
  }

  @Input()
  set startDateTime(value: string | null) {
    this._startDateTime = value;
    // parse dateTime to date and time
    if (value) {
      const [date, time] = value.split(' ');
      this.startDate.set(date);
      this.startTime.set(time);
    }
  }

  private _endDateTime: string | null = null;

  get endDateTime(): string | null {
    return this._endDateTime;
  }

  @Input()
  set endDateTime(value: string | null) {
    this._endDateTime = value;
    // parse dateTime to date and time
    if (value) {
      const [date, time] = value.split(' ');
      this.endDate.set(date);
      this.endTime.set(time);
    }
  }

  // window resize listener
  @HostListener('window:resize', ['$event'])
  public onResize(): void {
    this.innerWidth.set(window.innerWidth);
  }

  public ngAfterViewInit(): void {
    this.innerWidth.set(window.innerWidth);
    this._setStyles();
  }

  private _setStyles(): void {
    const shadowRoot: DocumentFragment = this.addToCalendarButton.nativeElement.shadowRoot;
    const initialized = shadowRoot.querySelector('.atcb-initialized');
    this._renderer2.setStyle(initialized, 'width', '100%');
    const wrapper = shadowRoot.querySelector('.atcb-button-wrapper');
    this._renderer2.setStyle(wrapper, 'padding', '0');
    const button = shadowRoot.querySelector('.atcb-button');
    this._renderer2.setStyle(button, 'font-size', '16px');
    this._renderer2.setStyle(button, 'max-width', '100%');
    this._renderer2.setStyle(button, 'min-width', '218px');
    this._renderer2.setStyle(button, 'margin', '0');
    this._renderer2.setStyle(button, 'border-radius', '9px');
    this._renderer2.setStyle(button, 'box-shadow', '0 20px 80px rgba(5, 82, 162, 0.3)');
    this._renderer2.setStyle(button, 'border', 'none');
    if (this.innerWidth() < 576) {
      this._renderer2.setStyle(button, 'height', '60px');
      this._renderer2.setStyle(button, 'line-height', '60px');
      this._renderer2.setStyle(button, 'padding', '0 0.75rem');
    } else {
      this._renderer2.setStyle(button, 'height', '76px');
      this._renderer2.setStyle(button, 'line-height', '76px');
      this._renderer2.setStyle(button, 'padding', '0 1rem');
    }
  }
}
