import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-validate-backend-message',
  template: `
    <ng-container *ngIf="control?.hasError('backend') && control?.errors?.['backend'] as errors">
      <p *ngFor="let error of errors | keyvalue" [@enterAnimation] class="vmc-error">
        {{ error.value }}
      </p>
    </ng-container>
  `,
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)', opacity: 0 }),
        animate('150ms', style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('150ms', style({ transform: 'translateX(100%)', opacity: 0 })),
      ]),
    ]),
  ],
  standalone: true,
  imports: [CommonModule],
})
export class ValidateBackendMessageComponent {
  @Input()
  public control: AbstractControl | null = null;
}
