import { InjectionToken } from '@angular/core';
import { WebinarType } from '@loreal-doctors-video-platform/data';

export interface OnRegisterLoggedClientToWebinar {
  onRegisterLoggedClientToWebinar(dto: {
    slug: string;
    eventTitle: string;
    startDateTime: string;
    endDateTime: string;
    type: WebinarType;
  }): void;
}

export const ON_REGISTER_LOGGED_CLIENT_TO_WEBINAR = new InjectionToken<OnRegisterLoggedClientToWebinar>(
  'ON_REGISTER_LOGGED_CLIENT_TO_WEBINAR',
);
