import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IListResponseData, SERVICES_API } from '@loreal-doctors-video-platform/data';
import { map, Observable } from 'rxjs';
import { ISpecializationDropdownDataItem } from '../_models/specialization-dropdown-data-item';
import { IWebinarListItem } from '../_models/webinar/webinar-list-item';
import { IWebinarDetails } from '../_models/webinar/webinar-details';
import { IWebinarDetailsForLoggedUser } from '../_models/webinar/webinar-details-for-logged-user';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class WebinarService {
  constructor(
    @Inject(SERVICES_API)
    private readonly _servicesApi: string,
    private readonly _httpClient: HttpClient,
    private readonly _store: Store,
  ) {}

  /**
   * Get list of client specializations
   */
  public fetchSpecializations(): Observable<ISpecializationDropdownDataItem[]> {
    return this._httpClient.get<ISpecializationDropdownDataItem[]>(
      `${this._servicesApi}/platform/client/specialization/dropdown`,
    );
  }

  /**
   * Get list of webinars
   */
  public fetchWebinarList(): Observable<IWebinarListItem[]> {
    return this._httpClient
      .get<IListResponseData<IWebinarListItem>>(`${this._servicesApi}/platform/webinar/list`, {
        params: {
          page: 1,
          pageSize: 99999,
          sort: 'start_date',
          order: 'desc',
        },
      })
      .pipe(
        map((response) => {
          // todo remove after tests
          // if (environment.production) {
          //   const user = this._store.selectSnapshot(AppState.user);
          //   const hiddenWebinarIds = [4];
          //   return response.data.filter((webinar) => {
          //     if (user?.email && ALLOWED_EMAILS_TO_ENTER_WEBINAR.some((email) => user.email.includes(email))) {
          //       return true;
          //     }
          //     return !hiddenWebinarIds.includes(webinar.id);
          //   });
          // }
          return response.data;
        }),
      );
  }

  /**
   * Get webinar details for logged client
   * @param slug
   */
  public findWebinarDetailsForLoggedClient(slug: string): Observable<IWebinarDetailsForLoggedUser> {
    return this._httpClient
      .get<IWebinarDetailsForLoggedUser>(`${this._servicesApi}/platform/client/webinar/${slug}/details`)
      .pipe(
        map((details) => ({
          ...details,
          webinar: {
            ...details.webinar,
            schedules: details?.webinar?.schedules
              ? details.webinar.schedules.map((schedule) => ({
                  ...schedule,
                  // replace all &amp; to &
                  name: schedule?.name ? schedule?.name.replace(/&amp;/g, '&') : null,
                  sub_name: schedule?.sub_name ? schedule?.sub_name.replace(/&amp;/g, '&') : null,
                  sub_name2: schedule?.sub_name2 ? schedule?.sub_name2.replace(/&amp;/g, '&') : null,
                }))
              : [],
          },
        })),
      );
  }

  /**
   * Get webinar details by slug
   * @param slug
   */
  public findWebinarDetailsBySlug(slug: string): Observable<IWebinarDetails> {
    return this._httpClient.get<IWebinarDetails>(`${this._servicesApi}/platform/webinar/${slug}/details`).pipe(
      map((webinar) => ({
        ...webinar,
        schedules: webinar?.schedules
          ? webinar?.schedules?.map((schedule) => ({
              ...schedule,
              // replace all &amp; to &
              name: schedule?.name ? schedule?.name.replace(/&amp;/g, '&') : null,
              sub_name: schedule?.sub_name ? schedule?.sub_name.replace(/&amp;/g, '&') : null,
              sub_name2: schedule?.sub_name2 ? schedule?.sub_name2.replace(/&amp;/g, '&') : null,
            }))
          : [],
      })),
    );
  }

  /**
   * Download webinar file by slug and file id
   * @param slug
   * @param fileId
   */
  public downloadFile(slug: string, fileId: number): Observable<Blob> {
    return this._httpClient.get<Blob>(
      `${this._servicesApi}/platform/client/webinar/${slug}/download/${fileId}/file-content`,
      {
        responseType: 'blob' as 'json',
      },
    );
  }

  /**
   * Download webinar certificate by slug
   * @param slug
   */
  public downloadCertificate(slug: string): Observable<Blob> {
    return this._httpClient.get<Blob>(`${this._servicesApi}/platform/client/webinar/${slug}/certificate`, {
      responseType: 'blob' as 'json',
    });
  }
}
