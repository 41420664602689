import { fromZonedTime, toZonedTime } from 'date-fns-tz';
import { parseISO } from 'date-fns/fp';

export const sourceTimeZone = 'Europe/Warsaw';

export function convertWarsawDateStringToCurrentTimezoneDate(sourceDateString: string): Date {
  const targetTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const sourceDate: Date = parseISO(sourceDateString); // Warsaw/Poland
  return toZonedTime(fromZonedTime(sourceDate, sourceTimeZone), targetTimeZone);
}
