export enum RouteSlug {
  HOME = '',

  WEBINAR = 'webinar',

  ACCOUNT = 'account',
  CHANGE_CURRENT_PASSWORD = 'change-current-password',
  WEBINARS = 'webinars',
  MATERIALS = 'materials',
  DATA = 'data',

  CRM = 'crm',

  LIVE_CHAT = 'live-chat',
  SURVEY = 'survey',

  AUTH = 'auth',
  LOGIN = 'login',
  RESET_PASSWORD = 'reset-password',
  REGISTRATION = 'registration',
  ACTIVATE_ACCOUNT = 'activate',
  LOG_IN_TO_WEBINAR_VIA_LINK = 'log-in-to-webinar-via-link',
  CHANGE_FORGOTTEN_PASSWORD = 'change-forgotten-password',
  CHANGE_PASSWORD = 'change-password',
  CONTACT = 'contact',

  UNSUBSCRIBE = 'unsubscribe',
  COOKIES_POLICY = 'cookies-policy',
  PRIVACY_POLICY = 'privacy-policy',
  NOT_FOUND_PAGE = '404',
  SSR_REDIRECT = 'ssr-redirect',
}
