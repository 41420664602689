<div class="acd-modal-content acd-cae-mod">
  <div class="col-12">
    <h4 class="mb-4" i18n="@@confirm-adding-event-to-calendar-modal.confirmation">
      Confirmation of adding the event to the calendar.
    </h4>
    <button (click)="closePopup()" class="close-btn" type="button">&times;</button>
  </div>

  <div class="col-12">
    <p class="mb-4" i18n="@@confirm-adding-event-to-calendar-modal.do-you-want">
      Would you like to add the event to your calendar?
    </p>
  </div>

  <div class="col-12 acd-modal-content__btns mt-3">
    <button (click)="closePopup()" class="dvp-btn dvp-btn--blue dvp-btn--blue--has-shadow dvp-btn--sm">
      <span i18n="@@confirm-adding-event-to-calendar-modal.no">No</span>
    </button>
    <dvp-wrap-add-to-calendar-button
      #addToCalendarButton
      dvpGtmClick
      [layer]="{
        event: 'uaevent',
        ecommerce: 'undefined',
        eventCategory: title.getTitle(),
        eventAction: 'add_event_to_calendar::' + eventName,
        eventLabel: label + '::add_event_to_calendar',
        module_name: eventName,
        cta_name: label,
        link_url: 'add_event_to_calendar'
      }"
      [eventName]="eventName"
      [language]="language"
      [startDateTime]="startDateTime"
      [endDateTime]="endDateTime"
      [label]="label"
    />
  </div>
</div>
