import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_ID, APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsModule, Store } from '@ngxs/store';
import { provideNgxMask } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { DOCUMENT, registerLocaleData } from '@angular/common';
import { SsrGuard } from './_guards/ssr.guard';
import {
  LanguageEnum,
  SERVICES_API,
  SERVICES_CUSTOMER_TOKEN,
  TRANSLATE_VALIDATION_MESSAGES,
} from '@loreal-doctors-video-platform/data';
import { JwtInterceptor } from './_interceptors/jwt.interceptor';
import { RECAPTCHA_LOADER_OPTIONS, RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import {
  AppState,
  AppStateClearUserData,
  AppStateFetchClientWebinarList,
  AppStateLogout,
  AppStateSetJwtToken,
  AppStateSetUserData,
} from './app.state';
import { NgSelectModule } from '@ng-select/ng-select';
import { UserService } from './_services/user.service';
import { EMPTY, firstValueFrom } from 'rxjs';
import { APP_COOKIES_KEY, APP_JWT_TOKEN, APP_TOKEN_EXPIRED_AT, LANGUAGE_KEY } from './app.consts';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { Router, RouterModule } from '@angular/router';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { APP_ROUTES } from './app.routing';
import { HttpErrorInterceptor } from './_interceptors/http-error.interceptor';
import { JwtHelperService } from '@auth0/angular-jwt';
import { IUser } from './_models/user';
import { getDocument } from 'ssr-window';
import { I18nModule } from './i18n.module';
import { LanguageInterceptor } from './_interceptors/language.interceptor';
import { TranslateValidationMessages } from './_helpers/translate-validation-messages';
import { ON_REGISTER_LOGGED_CLIENT_TO_WEBINAR } from './_tokens/register-logged-client-to-webinar.token';
import { RegisterLoggedClientToWebinarService } from './_services/register-logged-client-to-webinar.service';
import { NbDialogModule, NbSidebarModule, NbThemeModule } from '@nebular/theme';
import { OneTrustService } from './_services/one-trust.service';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { Angulartics2Module } from 'angulartics2';
import localeEn from '@angular/common/locales/en';
import localePl from '@angular/common/locales/pl';
import { isJwtTokenExpired } from './_helpers/is-jwt-token-expired';
import * as Sentry from '@sentry/angular-ivy';

const registerLocale = (language: LanguageEnum) => {
  switch (language) {
    case LanguageEnum.PL:
      registerLocaleData(localePl);
      break;
    case LanguageEnum.EN:
      registerLocaleData(localeEn);
      break;
  }
};

const LOCAL_STORAGE_STATE_VALUES = [APP_JWT_TOKEN, APP_TOKEN_EXPIRED_AT, APP_COOKIES_KEY, LANGUAGE_KEY];

const NGXS_MODULES = [
  NgxsModule.forRoot([AppState], {
    developmentMode: !environment.production,
    selectorOptions: {
      suppressErrors: false,
      injectContainerState: false,
    },
    compatibility: {
      strictContentSecurityPolicy: true,
    },
  }),
  NgxsRouterPluginModule.forRoot(),
  NgxsFormPluginModule.forRoot(),
  NgxsLoggerPluginModule.forRoot({
    logger: console,
    collapsed: false,
    disabled: environment.production,
  }),
  NgxsStoragePluginModule.forRoot({
    storage: StorageOption.LocalStorage,
    key: [...LOCAL_STORAGE_STATE_VALUES],
  }),
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    NbThemeModule.forRoot(),
    NbDialogModule.forRoot(),
    NbSidebarModule.forRoot(),
    NgSelectModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HammerModule,
    RecaptchaV3Module,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-right',
    }),
    QuicklinkModule,
    Angulartics2Module.forRoot(),
    RouterModule.forRoot(APP_ROUTES, {
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: QuicklinkStrategy,
      initialNavigation: 'enabledBlocking',
      anchorScrolling: 'enabled',
    }),
    // for NgxIntlTelInputModule
    BsDropdownModule.forRoot(),
    ...NGXS_MODULES,
  ],
  providers: [
    provideNgxMask(),
    SsrGuard,
    JwtHelperService,
    { provide: APP_ID, useValue: 'serverApp' },
    I18nModule.setLocale(),
    I18nModule.setLocaleId(),
    {
      provide: RECAPTCHA_LOADER_OPTIONS,
      useFactory: (locale: string) => ({
        onBeforeLoad(url: URL) {
          url.searchParams.set('hl', locale);
          return { url };
        },
      }),
      deps: [LOCALE_ID],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    { provide: TRANSLATE_VALIDATION_MESSAGES, useClass: TranslateValidationMessages },
    { provide: DOCUMENT, useValue: getDocument() },
    { provide: SERVICES_API, useValue: environment.api },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptchaV3SiteKey,
    },
    {
      provide: SERVICES_CUSTOMER_TOKEN,
      useFactory: (store: Store) => () => store.selectSnapshot(AppState.token),
      deps: [Store],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (userService: UserService, store: Store) => async () => {
        const language = store.selectSnapshot(AppState.language);
        registerLocale(language);
        const { token } = store.selectSnapshot(AppState);
        let onlyChatToken = false;
        if (token) {
          const jwtHelperService: JwtHelperService = new JwtHelperService();
          const decodedToken = jwtHelperService.decodeToken(token);
          onlyChatToken = decodedToken.role === 'ROLE_CLIENT_LOGIN_WEBINAR';
        }
        if (!token || isJwtTokenExpired(token)) {
          store.dispatch(new AppStateClearUserData());
          return EMPTY;
        }
        return firstValueFrom(userService.getMe())
          .then((user: IUser) => {
            store.dispatch(
              onlyChatToken
                ? [new AppStateSetUserData({ user })]
                : [new AppStateFetchClientWebinarList({ refreshWebinarList: true }), new AppStateSetUserData({ user })],
            );
          })
          .catch((reason) => {
            if (reason?.status === 403) {
              setTimeout(() => {
                store.dispatch([new AppStateSetJwtToken({ token: null }), new AppStateLogout()]);
              }, 200);
            }
          });
      },
      deps: [UserService, Store, OneTrustService, Sentry.TraceService],
      multi: true,
    },
    // {
    //   provide: ErrorHandler,
    //   useFactory: globalSentryExtractorErrorsHandler,
    //   deps: [Injector],
    // },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
        logErrors: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: ON_REGISTER_LOGGED_CLIENT_TO_WEBINAR,
      useClass: RegisterLoggedClientToWebinarService,
    },
    UserService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
