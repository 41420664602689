export const APP_COOKIES_KEY = 'app.cookiesAllowed';
export const APP_JWT_TOKEN = 'app.token';
export const APP_TOKEN_EXPIRED_AT = 'app.tokenExpiredAt';
export const LANGUAGE_KEY = 'app.language';
export const ALLOWED_EMAILS_TO_ENTER_WEBINAR = [
  '@h2h.tech',
  'karolina.lunic@lovebrandsgroup.pl',
  'marta.domanska@lovebrandsmedical.pl',
  'patryk.makowski@digitalspace.pl',
  'jaroslaw.jablonski@digitalspace.pl',
  'marta.radzikowska@loreal.com',
  'diana.wolanska-buzalska@loreal.com',
  'mateusz.konwerski@lovebrandsgroup.pl',
  'aleksandra.gwozdz@loreal.com',
  'blazej.pasnicki@groupm.com',
  'testowymail@gmail.com',
  'kmenderska@icloud.com',
  'joanna.galecka-malczyk@loreal.com',
  'inez.golda@loreal.com',
];
