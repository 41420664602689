import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { IUser } from './_models/user';
import { concatMap, forkJoin, Observable, of, tap, throwError } from 'rxjs';
import { WebinarService } from './_services/webinar.service';
import { catchError, finalize } from 'rxjs/operators';
import { UserService } from './_services/user.service';
import { Navigate } from '@ngxs/router-plugin';
import { RouteSlug } from './_models/route-slug.enum';
import { jsonToHtml, LanguageEnum } from '@loreal-doctors-video-platform/data';
import { LANGUAGE_KEY } from './app.consts';
import { ISpecializationDropdownDataItem } from './_models/specialization-dropdown-data-item';
import { IWebinarListItem } from './_models/webinar/webinar-list-item';
import { IWebinarDetails } from './_models/webinar/webinar-details';
import { IWebinarClientListItem } from './_models/webinar/webinar-client-list-item';
import { saveAs } from 'file-saver';
import { IWebinarDownloadDetails } from './_models/webinar/webinar-download-details';
import { IWebinarDetailsForLoggedUser } from './_models/webinar/webinar-details-for-logged-user';
import { environment } from '../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { snakeCase } from 'lodash-es';

export function getDefaultLanguage(): LanguageEnum {
  const localStoreLanguage = localStorage.getItem(LANGUAGE_KEY)?.replace(/(['"])/g, '') as LanguageEnum;
  if (localStoreLanguage) {
    return localStoreLanguage;
  }

  const userLocaleLanguage = window.navigator.language.split(/[-_]/)[0];
  const availableLanguages = [LanguageEnum.PL.valueOf(), LanguageEnum.EN.valueOf()];
  if (availableLanguages.includes(userLocaleLanguage)) {
    return userLocaleLanguage as LanguageEnum;
  }

  return LanguageEnum.PL;
}

export class AppStateSetJwtToken {
  public static readonly type = '[AppState] set jwt token';

  constructor(public readonly payload: { token: string | null }) {}
}

export class AppStateLogout {
  public static readonly type = '[AppState] logout';
}

export class AppStateClearUserData {
  public static readonly type = '[AppState] clear user data';
}

export class AppStateGetUserData {
  public static readonly type = '[AppState] get user data';
}

export class AppStateSetUserData {
  public static readonly type = '[AppState] set user data';

  constructor(public readonly payload: { user: IUser }) {}
}

export class AppStateSetLanguage {
  public static readonly type = '[AppState] set language';

  constructor(public readonly payload: { language: LanguageEnum }) {}
}

export class AppStateFetchSpecializations {
  public static readonly type = '[AppState] fetch specializations';
}

export class AppStateFetchWebinarList {
  public static readonly type = '[AppState] fetch webinar list';
}

export class AppStateFetchClientWebinarList {
  public static readonly type = '[AppState] fetch client webinar list';

  constructor(public readonly payload: { refreshWebinarList: boolean }) {}
}

export class AppStateRegisterLoggedClientToWebinar {
  public static readonly type = '[AppState] register logged client to webinar';

  constructor(public readonly payload: { slug: string }) {}
}

export class AppStateFindWebinarDetailsBySlug {
  public static readonly type = '[AppState] find webinar details by slug';

  constructor(public readonly payload: { slug: string }) {}
}

export class AppStateFindWebinarDetailsForLoggedClientBySlug {
  public static readonly type = '[AppState] find webinar details for logged client by slug';

  constructor(public readonly payload: { slug: string }) {}
}

export class AppStateClearWebinar {
  public static readonly type = '[AppState] clear webinar ';
}

export class AppStateDownloadFileOfWebinar {
  public static readonly type = '[AppState] download file of webinar';

  constructor(public readonly payload: { slug: string; webinarDownloadDetails: IWebinarDownloadDetails }) {}
}

export class AppStateDownloadWebinarCertificate {
  public static readonly type = '[AppState] download webinar certificate';

  constructor(public readonly payload: { slug: string; language: LanguageEnum }) {}
}

export interface AppStateModel {
  loading: boolean;
  cookiesAllowed: boolean | null;
  token: string | null;
  user: IUser | null;
  language: LanguageEnum;
  specializations: ISpecializationDropdownDataItem[] | null;
  webinars: IWebinarListItem[] | null;
  clientWebinars: IWebinarClientListItem[];
  webinar: IWebinarDetails | null;
  webinarDetailsForLoggedUser: IWebinarDetailsForLoggedUser | null;
  webinarSlug: string | null;
}

@State<AppStateModel>({
  name: 'app',
  defaults: {
    language: getDefaultLanguage(),
    loading: false,
    cookiesAllowed: null,
    token: null,
    user: null,
    specializations: null,
    webinars: null,
    clientWebinars: [],
    webinar: null,
    webinarDetailsForLoggedUser: null,
    webinarSlug: null,
  },
})
@Injectable()
export class AppState {
  constructor(
    @Inject(LOCALE_ID)
    public readonly locale: string,
    private readonly _dictionariesService: WebinarService,
    private readonly _userService: UserService,
  ) {}

  @Selector()
  public static loading({ loading }: AppStateModel): boolean {
    return loading;
  }

  @Selector()
  public static token({ token }: AppStateModel): string | null {
    return token;
  }

  @Selector()
  public static isLoggedToWebinar({ token }: AppStateModel): boolean {
    if (!token) {
      return false;
    }
    const jwtHelperService: JwtHelperService = new JwtHelperService();
    const decodedToken = jwtHelperService.decodeToken(token);
    return decodedToken.role === 'ROLE_CLIENT_LOGIN_WEBINAR';
  }

  @Selector()
  public static user({ user }: AppStateModel): IUser | null {
    return user;
  }

  @Selector()
  public static language({ language }: AppStateModel): LanguageEnum {
    return language;
  }

  @Selector()
  public static specializations({ specializations }: AppStateModel): ISpecializationDropdownDataItem[] | null {
    return specializations;
  }

  @Selector()
  public static webinars({ webinars }: AppStateModel): IWebinarListItem[] | null {
    return webinars;
  }

  @Selector()
  public static clientWebinars({ clientWebinars }: AppStateModel): IWebinarClientListItem[] {
    return clientWebinars;
  }

  @Selector()
  public static webinar({ webinar }: AppStateModel): IWebinarDetails | null {
    return webinar;
  }

  @Selector()
  public static webinarDetailsForLoggedUser({
    webinarDetailsForLoggedUser,
  }: AppStateModel): IWebinarDetailsForLoggedUser | null {
    return webinarDetailsForLoggedUser;
  }

  @Selector()
  public static webinarSlug({ webinarSlug }: AppStateModel): string | null {
    return webinarSlug;
  }

  @Action(AppStateSetUserData)
  public setUserData({ patchState }: StateContext<AppStateModel>, { payload: { user } }: AppStateSetUserData): void {
    patchState({
      user,
    });
  }

  @Action(AppStateSetLanguage)
  public setLanguage(
    { patchState, getState }: StateContext<AppStateModel>,
    { payload: { language } }: AppStateSetLanguage,
  ): void {
    if (language === getState().language) {
      return;
    }
    patchState({
      language,
    });
    location.reload();
  }

  @Action(AppStateGetUserData)
  public getUserData({ patchState }: StateContext<AppStateModel>): Observable<[IUser, IWebinarClientListItem[]]> {
    patchState({ loading: true });
    return forkJoin([this._userService.getMe(), this._userService.fetchClientWebinars()]).pipe(
      tap(([user, clientWebinars]) => {
        patchState({
          user,
          clientWebinars,
        });
      }),
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }

  @Action(AppStateSetJwtToken)
  public setJwtToken({ patchState }: StateContext<AppStateModel>, { payload: { token } }: AppStateSetJwtToken): void {
    patchState({ token });
  }

  @Action(AppStateLogout)
  public logout({ patchState, dispatch, getState }: StateContext<AppStateModel>): Observable<void | null> {
    const { token } = getState();
    if (!token) {
      patchState({ user: null, token: null, clientWebinars: [] });
      dispatch(new Navigate([RouteSlug.AUTH, RouteSlug.LOGIN]));
      return of(null);
    }
    patchState({ loading: true });
    return this._userService.logout().pipe(
      tap(() => {
        patchState({ user: null, token: null, clientWebinars: [], loading: false });
        dispatch(new Navigate([RouteSlug.AUTH, RouteSlug.LOGIN]));
      }),
      catchError((err) => {
        patchState({ user: null, token: null, clientWebinars: [], loading: false });
        dispatch(new Navigate([RouteSlug.AUTH, RouteSlug.LOGIN]));
        return throwError(() => err);
      }),
    );
  }

  @Action(AppStateClearUserData)
  public clearUserData({ patchState }: StateContext<AppStateModel>): void {
    patchState({ user: null, token: null, clientWebinars: [] });
  }

  @Action(AppStateFetchSpecializations)
  public fetchSpecializations({
    patchState,
    getState,
  }: StateContext<AppStateModel>): Observable<ISpecializationDropdownDataItem[]> {
    if (getState().specializations) {
      return of(getState().specializations || []);
    }
    patchState({ loading: true });
    return this._dictionariesService.fetchSpecializations().pipe(
      tap((_specializations) => {
        patchState({ loading: false, specializations: _specializations });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(() => err);
      }),
    );
  }

  @Action(AppStateFetchWebinarList)
  public fetchWebinarList({ patchState, getState }: StateContext<AppStateModel>): Observable<IWebinarListItem[]> {
    if (getState().webinars) {
      return of(getState().webinars || []);
    }
    patchState({ loading: true });
    return this._dictionariesService.fetchWebinarList().pipe(
      tap((_webinars) => {
        patchState({ loading: false, webinars: _webinars });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(() => err);
      }),
    );
  }

  @Action(AppStateFetchClientWebinarList)
  public fetchClientWebinarList(
    { patchState, getState }: StateContext<AppStateModel>,
    { payload: { refreshWebinarList } }: AppStateFetchClientWebinarList,
  ): Observable<IWebinarClientListItem[]> {
    if (getState().clientWebinars && !refreshWebinarList) {
      return of(getState().clientWebinars);
    }
    patchState({ loading: true });
    return this._userService.fetchClientWebinars().pipe(
      tap((clientWebinars) => {
        patchState({ loading: false, clientWebinars });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(() => err);
      }),
    );
  }

  @Action(AppStateRegisterLoggedClientToWebinar)
  public registerLoggedClientToWebinar(
    { patchState, dispatch }: StateContext<AppStateModel>,
    { payload: { slug } }: AppStateRegisterLoggedClientToWebinar,
  ): Observable<void> {
    patchState({ loading: true });
    return this._userService.registerLoggedUserToWebinar(slug).pipe(
      concatMap(() => dispatch(new AppStateFetchClientWebinarList({ refreshWebinarList: true }))),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(() => err);
      }),
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }

  @Action(AppStateFindWebinarDetailsBySlug)
  public findWebinarDetailsBySlug(
    { patchState }: StateContext<AppStateModel>,
    { payload: { slug } }: AppStateFindWebinarDetailsBySlug,
  ): Observable<IWebinarDetails> {
    patchState({ loading: true, webinarSlug: slug, webinar: null });
    return this._dictionariesService.findWebinarDetailsBySlug(slug).pipe(
      tap((webinar) => {
        patchState({
          loading: false,
          webinar: {
            ...webinar,
            description: webinar.description
              ? jsonToHtml(webinar.description ? webinar.description : '{}').replace(
                  new RegExp('/uploads/', 'g'),
                  `${environment.api}/uploads/`,
                )
              : null,
            speakers: webinar.speakers.map((speaker) => ({
              ...speaker,
              description: speaker.description
                ? jsonToHtml(speaker.description ? speaker.description : '{}').replace(
                    new RegExp('/uploads/', 'g'),
                    `${environment.api}/uploads/`,
                  )
                : null,
            })),
            schedules: webinar.schedules.map((schedule) => ({
              ...schedule,
              speakers: schedule.speakers.map((speaker) => ({
                ...speaker,
                description: speaker.description
                  ? jsonToHtml(speaker.description ? speaker.description : '{}').replace(
                      new RegExp('/uploads/', 'g'),
                      `${environment.api}/uploads/`,
                    )
                  : null,
              })),
            })),
          },
        });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(() => err);
      }),
    );
  }

  @Action(AppStateFindWebinarDetailsForLoggedClientBySlug)
  public findWebinarDetailsForLoggedClientBySlug(
    { patchState }: StateContext<AppStateModel>,
    { payload: { slug } }: AppStateFindWebinarDetailsForLoggedClientBySlug,
  ): Observable<IWebinarDetailsForLoggedUser> {
    patchState({ loading: true, webinarSlug: slug, webinar: null, webinarDetailsForLoggedUser: null });
    return this._dictionariesService.findWebinarDetailsForLoggedClient(slug).pipe(
      tap((webinarDetailsForLoggedUser) => {
        patchState({
          loading: false,
          webinarDetailsForLoggedUser,
          webinar: {
            ...webinarDetailsForLoggedUser.webinar,
            description: webinarDetailsForLoggedUser.webinar.description
              ? jsonToHtml(
                  webinarDetailsForLoggedUser.webinar.description
                    ? webinarDetailsForLoggedUser.webinar.description
                    : '{}',
                ).replace(new RegExp('/uploads/', 'g'), `${environment.api}/uploads/`)
              : null,
            speakers: webinarDetailsForLoggedUser.webinar.speakers.map((speaker) => ({
              ...speaker,
              description: speaker.description
                ? jsonToHtml(speaker.description ? speaker.description : '{}').replace(
                    new RegExp('/uploads/', 'g'),
                    `${environment.api}/uploads/`,
                  )
                : null,
            })),
            schedules: webinarDetailsForLoggedUser.webinar.schedules.map((schedule) => ({
              ...schedule,
              speakers: schedule.speakers.map((speaker) => ({
                ...speaker,
                description: speaker.description
                  ? jsonToHtml(speaker.description ? speaker.description : '{}').replace(
                      new RegExp('/uploads/', 'g'),
                      `${environment.api}/uploads/`,
                    )
                  : null,
              })),
            })),
          },
        });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(() => err);
      }),
    );
  }

  @Action(AppStateClearWebinar)
  public clearWebinarSlug({ patchState }: StateContext<AppStateModel>): void {
    patchState({ webinarSlug: null, webinar: null });
  }

  @Action(AppStateDownloadFileOfWebinar)
  public downloadFile(
    { patchState }: StateContext<AppStateModel>,
    { payload: { slug, webinarDownloadDetails } }: AppStateDownloadFileOfWebinar,
  ): Observable<Blob> {
    patchState({ loading: true });
    const extension = webinarDownloadDetails.original_name.split('.').pop();
    return this._dictionariesService.downloadFile(slug, webinarDownloadDetails.id).pipe(
      tap((blob) => {
        if (this.locale === 'pl') {
          saveAs(blob, `${webinarDownloadDetails.name_pl}.${extension}`);
        } else {
          saveAs(blob, `${webinarDownloadDetails.name_en}.${extension}`);
        }
      }),
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }

  @Action(AppStateDownloadWebinarCertificate)
  public downloadWebinarCertificate(
    { patchState, getState }: StateContext<AppStateModel>,
    { payload: { slug, language } }: AppStateDownloadWebinarCertificate,
  ): Observable<Blob> {
    const { clientWebinars } = getState();
    const webinar = clientWebinars.find((webinar) => webinar.webinar.slug === slug);
    patchState({ loading: true });
    return this._dictionariesService.downloadCertificate(slug).pipe(
      tap((blob) => {
        const webinarTitle = webinar?.webinar.title;
        const certificateString = language === LanguageEnum.PL ? 'certyfikat' : 'certificate';
        saveAs(blob, `${webinarTitle ? `${snakeCase(webinarTitle)}_${certificateString}` : certificateString}.pdf`);
      }),
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }
}
