import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-check-strength',
  template: `
    <ul #ul id="strength">
      <li class="point"></li>
      <li class="point"></li>
      <li class="point"></li>
      <li class="point"></li>
      <li class="point"></li>
    </ul>
  `,
  styleUrls: ['./check-strength.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class CheckStrengthComponent implements OnChanges {
  @Input()
  public inputStr: string = '';
  @Output()
  public outputStr = new EventEmitter<number>();
  @ViewChild('ul', { static: true })
  public ul: ElementRef | undefined;

  public strength = {
    colors: ['#ff7171', '#F90', '#FF0', '#9F0', '#218838'],
    measureStrength: (password: string) => {
      let _force = 0;
      const _regex = /[’#@$-/:-?{-~!"^_`\\[\]]/g;

      const _lowerLetters = /[a-z]+/.test(password);
      const _upperLetters = /[A-Z]+/.test(password);
      const _numbers = /\d+/.test(password);
      const _symbols = _regex.test(password);

      const _flags = [_lowerLetters, _upperLetters, _numbers, _symbols];
      const _passedMatches = _flags.filter((el) => el).length;

      _force += password.length + (password.length >= 12 ? 10 : 0);

      _force += _passedMatches * 6;

      // penality (short password)
      _force = password.length <= 6 ? Math.min(_force, 10) : _force;

      // penality (poor variety of characters)
      _force = _passedMatches === 1 ? Math.min(_force, 10) : _force;
      _force = _passedMatches === 2 ? Math.min(_force, 20) : _force;
      _force = _passedMatches === 3 ? Math.min(_force, 40) : _force;

      return _force;
    },
    getColor: function (s: any) {
      let idx: number;
      if (s <= 10) {
        idx = 0;
      } else if (s <= 20) {
        idx = 1;
      } else if (s <= 30) {
        idx = 2;
      } else if (s <= 40) {
        idx = 3;
      } else {
        idx = 4;
      }
      return { idx: idx + 1, col: this.colors[idx] };
    },
  };

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.ul?.nativeElement) {
      return;
    }
    const currentValue = changes['inputStr'].currentValue;
    if (!currentValue) {
      this.ul.nativeElement.style.display = 'none';
    }
    const previousValue = changes['inputStr'].previousValue;
    if (this.strength && currentValue && currentValue !== previousValue) {
      const s = this.strength.measureStrength(currentValue);
      const c = this.strength.getColor(s);

      this.ul.nativeElement.style.display = 'flex';
      const li = this.ul.nativeElement.children;

      for (let i = 0; i < li.length; i++) {
        li[i].style.background = '#DDD';
      }

      for (let i = 0; i < c.idx; i++) {
        li[i].style.background = c.col;
      }

      this.outputStr.emit(c.idx);
    }
  }
}
