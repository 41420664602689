import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { IUser } from '../_models/user';
import { IListResponseData, SERVICES_API } from '@loreal-doctors-video-platform/data';
import { IWebinarClientListItem } from '../_models/webinar/webinar-client-list-item';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    @Inject(SERVICES_API)
    private readonly _servicesApi: string,
    private readonly _httpClient: HttpClient,
    private readonly _store: Store,
  ) {}

  /**
   * Logout user. Destroy session.
   */
  public logout(): Observable<void> {
    return this._httpClient.get<void>(`${this._servicesApi}/platform/logout`);
  }

  /**
   * Get logged user details.
   */
  public getMe(): Observable<IUser> {
    return this._httpClient.get<IUser>(`${this._servicesApi}/platform/client/me`);
  }

  /**
   * Register logged client to webinar
   */
  public registerLoggedUserToWebinar(slug: string): Observable<void> {
    return this._httpClient.post<void>(`${this._servicesApi}/platform/client/webinar/${slug}/register`, null);
  }

  /**
   * Get list of client webinars
   */
  public fetchClientWebinars(): Observable<IWebinarClientListItem[]> {
    return this._httpClient
      .get<IListResponseData<IWebinarClientListItem>>(`${this._servicesApi}/platform/client/webinar/list`)
      .pipe(
        map((response) => {
          // // todo remove after tests
          // if (environment.production) {
          //   const user = this._store.selectSnapshot(AppState.user);
          //   const hiddenWebinarIds = [4];
          //   return response.data.filter((webinar) => {
          //     if (user?.email && ALLOWED_EMAILS_TO_ENTER_WEBINAR.some((email) => user.email.includes(email))) {
          //       return true;
          //     }
          //     return !hiddenWebinarIds.includes(webinar?.webinar?.id);
          //   });
          // }
          return response.data;
        }),
      );
  }
}
