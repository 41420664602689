import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { AppStateLogout } from '../app.state';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private readonly _store: Store) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403) {
          this._store.dispatch(new AppStateLogout());
        }
        return throwError(() => error);
      }),
    );
  }
}
