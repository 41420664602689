import { Directive, HostListener, Input } from '@angular/core';
import { Angulartics2GoogleTagManager } from 'angulartics2';

@Directive({
  selector: '[dvpGtmClick]',
  standalone: true,
})
export class GtmClickDirective {
  @Input({ required: true })
  public layer!: object;

  @Input()
  public isFormValid = true;

  constructor(private readonly _angulartics2GoogleTagManager: Angulartics2GoogleTagManager) {}

  @HostListener('click')
  public onClick(): void {
    if (!this.isFormValid) {
      return;
    }
    this._angulartics2GoogleTagManager.pushLayer(this.layer);
  }
}
